import '../App.scss';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function NotFound() {
	const { t } = useTranslation();
	return (
		<Container>
			<Helmet>
				<title>{t('404.headTitle')} ■ {t('global.siteDescription')}</title>
				<meta name="description" content={t('404.headDesc')} />
			</Helmet>
			<div className="d-flex py-5 flex-column">
				<h1 className="m-auto mt-5">{t('404.title')}</h1>
				<p className="lead m-auto">{t('404.description')}</p>
			</div>
		</Container>
	);
}

export default NotFound;
