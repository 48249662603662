import '../App.scss';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import instructors from '../Instructors/instructors.json';
import InstructorsBlock from '../Components/InstructorsBlock';

function About() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('aboutPage.headTitle')} ■ {t('global.siteDescription')}</title>
				<meta name="description" content={t('aboutPage.headDesc')} />
			</Helmet>
			<Container>
				<div className="about-hero">
					<h1>{t('aboutPage.title')}</h1>
					<p className="lead">
						{t('aboutPage.description')}
					</p>
				</div>
				<div className="gallery mb-12">
					<figure className="gallery__item gallery__item--1 mb-0">
						<img
							src="/assets/about1.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
					<figure className="gallery__item gallery__item--2 mb-0">
						<img
							src="/assets/about2.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
					<figure className="gallery__item gallery__item--3 mb-0">
						<img
							src="/assets/about3.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
					<figure className="gallery__item gallery__item--4 mb-0">
						<img
							src="/assets/about4.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
					<figure className="gallery__item gallery__item--5 mb-0">
						<img
							src="/assets/about5.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
					<figure className="gallery__item gallery__item--6 mb-0">
						<img
							src="/assets/about6.jpg"
							alt="Gallery image 1"
							className="gallery__img rounded-3"
						/>
					</figure>
				</div>
			</Container>

			<div className="about-benefits">
				<Container>
					<h2>{t('aboutPage.featuresTitle')}:</h2>
					<ol className="about-benefits-list">
						<li>
							{t('aboutPage.feature1')}
						</li>
						<li>
							{t('aboutPage.feature2')}
						</li>
						<li>
							{t('aboutPage.feature3')}
						</li>
						<li>
							{t('aboutPage.feature4')}
						</li>
						<li>
							{t('aboutPage.feature5')}
						</li>
						<li>
							{t('aboutPage.feature6')}
						</li>
						<li>
							{t('aboutPage.feature7')}
						</li>
					</ol>
				</Container>
			</div>
			<div className="about-instructors">
				<Container>
					<InstructorsBlock instructors={instructors} />
				</Container>
			</div>
		</>
	);
}

export default About;
