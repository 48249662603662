import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import CourseFpv from '../pages/CourseFpv';
import CourseMavic from '../pages/CourseMavic';
import CourseEngineer from '../pages/CourseEngineer';
import CourseShulika from '../pages/CourseShulika';
import About from '../pages/About';
import News from '../pages/News';
import Contact from '../pages/Contact';
import Donation from '../pages/Donation';
import NotFound from '../pages/NotFound';
import Layout from '../layout/Layout';
import { updateUrlLanguageCode } from './utils';
import { LANGUAGES } from '../constants.ts';

const RootRoutes = () => {
	updateUrlLanguageCode();
	const stack = (
		<>
			<Route index element={<Home />} />
			<Route path="about" element={<About />} />
			<Route path="donation" element={<Donation />} />
			<Route path="news" element={<News />} />
			<Route path="contact" element={<Contact />} />
			<Route path="vykorystannya-fpv-v-boyovykh-umovakh" element={<CourseFpv />} />
			<Route
				path="vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh"
				element={<CourseMavic />}
			/>
			<Route path="fpv-inzhenernyy-kurs" element={<CourseEngineer />} />
			<Route path="nichnyy-bomber-shulika" element={<CourseShulika />} />
			<Route path="*" element={<NotFound />} />
		</>
	);

	return (
		<Routes>
			<Route path={'/'} element={<Layout />}>
				{stack}
			</Route>
			<Route path={'/en'} element={<Layout />}>
				{stack}
			</Route>
		</Routes>
	);
};

export default RootRoutes;
