import {useTranslation} from 'react-i18next';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function CoursesBlock() {
    const {t} = useTranslation();
    return (
        <>
            <h2 className="home-screen-2-h2" id="our-services">
                {t('navbar.ourCourses')}
            </h2>
            <Row>
                <Col lg={6}>
                    <div className="service-block service-block-1">
                        <div className="service-block-icon"></div>
                        <h3>
                            <a href="">{t('courseFpv.title')}</a>
                        </h3>
                        <div className="service-block-btn">
                            <Button
                                href="/vykorystannya-fpv-v-boyovykh-umovakh"
                                variant="primary"
                                className="text-white btn-xl text-uppercase"
                            >
                                {t('btn.details')}
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="service-block service-block-2">
                        <div className="service-block-icon"></div>
                        <h3>
                            <a href="">
                                {t('courseMavic.title')}
                            </a>
                        </h3>
                        <div className="service-block-btn">
                            <Button
                                href="/vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh"
                                variant="primary"
                                className="text-white btn-xl text-uppercase"
                            >
                                {t('btn.details')}
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="service-block service-block-3">
                        <div className="service-block-icon"></div>
                        <h3>
                            <a href="">{t('courseEngineer.title')}</a>
                        </h3>
                        <div className="service-block-btn">
                            <Button
                                href="/fpv-inzhenernyy-kurs"
                                variant="primary"
                                className="text-white btn-xl text-uppercase"
                            >
                                {t('btn.details')}
                            </Button>
                        </div>
                    </div>
                </Col>
                {/*<Col lg={6}>*/}
                {/*    <div className="service-block service-block-4">*/}
                {/*        <div className="service-block-icon"></div>*/}
                {/*        <h3>*/}
                {/*            <a href="">{t('courseShulika.title')}</a>*/}
                {/*        </h3>*/}
                {/*        <div className="service-block-btn">*/}
                {/*            <Button*/}
                {/*                href="/nichnyy-bomber-shulika"*/}
                {/*                variant="primary"*/}
                {/*                className="text-white btn-xl text-uppercase"*/}
                {/*            >*/}
                {/*                {t('btn.details')}*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        </>
    );
}

export default CoursesBlock;
