import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import Footer from './Footer';

function Layout() {
	return (
		<div className="d-flex flex-column h-100">
			<TopNav />
			<main className="flex-shrink-0">
				<Outlet />
			</main>
			<Footer />
		</div>
	);
}

export default Layout;
