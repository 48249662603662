import { useTranslation } from 'react-i18next';
import { config } from "../constants.ts";
import Button from "react-bootstrap/Button";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function CourseSummary({course}) {
	const { t } = useTranslation();
	const { civil, military } = config.price[course];
	const translationKey = `${course}.summary`;

	// console.log(config.price.${nameCourse});
	const summary = t(translationKey, { returnObjects: true });
	return (
		<div className="summary-box sticky-top">
			<div className="summary-box-holder">
				<h3>{t('global.titleSummary')}:</h3>
				<ul className="summary-box-holder-list">
					{summary.map(summaryStep => (
						<li key={summaryStep}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 333 398.75"
								x="0px"
								y="0px"
							>
								<g>
									<path d="M54 154l64 74 187 -222c14,-15 36,1 25,18l-184 281c-14,18 -33,20 -49,2l-93 -111c-18,-26 28,-63 50,-42z" />
								</g>
							</svg>
							{summaryStep}
						</li>
					))}
				</ul>
				<div className="summary-box-holder-price">
					<Tabs
						defaultActiveKey="military"
					>
						<Tab eventKey="military" title={t('global.titleSummaryMilitary')}>
							{military} <small>{t('global.currency')}.</small>
						</Tab>
						<Tab eventKey="civil" title={t('global.titleSummaryCivil')}>
							{civil} <small>{t('global.currency')}.</small>
						</Tab>
					</Tabs>
				</div>
				<div className="summary-box-holder-btn">
					<Button
						variant="primary"
						href="https://docs.google.com/forms/d/e/1FAIpQLSey0sMXsi_RA7VfwHuTlDUZbMwETwB_TOUEM6aijdgS6E0-CA/formResponse"
						className="text-white btn-xl text-uppercase"
					>
						{t('btn.JoinTheCourse')}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default CourseSummary;
