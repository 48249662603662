import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { useEffect } from 'react';
import RootRoutes from './router/RootRoutes';

export default function App() {
	useEffect(() => {
		const togglePageBodyClass = (element, pathname) => {
			const pathnameArr = pathname.split('/');
			const secondaryPagesClassname = pathnameArr[pathnameArr.length - 1];
			const className = pathname === '/' ? 'home' : secondaryPagesClassname;

			if (!element.classList.contains(className)) {
				if (className) element.classList.add(className);
			} else {
				if (element.classList.contains(className)) element.classList.remove(className);
			}
		};

		const { pathname } = location;
		const body = document.getElementsByTagName('body')[0];

		togglePageBodyClass(body, pathname);
	}, []);

	return (
		<BrowserRouter>
			<RootRoutes />
		</BrowserRouter>
	);
}
