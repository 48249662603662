import '../App.scss';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';

function News() {
	return (
		<Container>
			<Helmet>
				<title>DragonSKY - школа підготовки операторів дронів</title>
				<meta name="description" content="DragonSKY" />
			</Helmet>
			<h1>News</h1>
		</Container>
	);
}

export default News;
