import i18n from '../i18n';
import { SUPPORTED_LANGS, LANGUAGES } from '../constants.ts';

export const lang_storage_key = 'dragonsky-user-lang';

export const updateUrlLanguageCode = () => {
	const storedLang = localStorage.getItem(lang_storage_key);
	const lang = storedLang ?? i18n.language;

	if (SUPPORTED_LANGS.includes(lang)) handleLanguageChange(lang);
};

export const handleLanguageChange = lang => {
	const langFromUrl = location.pathname.split('/')[1];
	const prefix = lang !== LANGUAGES.UA ? `/${lang}` : '';

	if (i18n.language !== lang) {
		i18n.changeLanguage(lang);
		localStorage.setItem(lang_storage_key, lang);
	}

	if (lang !== LANGUAGES.UA && langFromUrl !== lang) {
		location.pathname = `${prefix}${location.pathname}`;
	} else if (SUPPORTED_LANGS.includes(langFromUrl) && langFromUrl !== lang) {
		location.pathname = location.pathname.replace(`/${langFromUrl}`, prefix);
	}
};
