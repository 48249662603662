import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import {handleLanguageChange} from '../router/utils';
import {LANGUAGES} from '../constants.ts';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';

function TopNav() {
    const {t} = useTranslation();

    const handleLangBntClick = e => {
        const lang = e.target.getAttribute('lang');
        handleLanguageChange(lang);
    };

    return (
        <Navbar expand="lg" className="navbar--ds" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="/assets/dragonsky_logo_white_tr_770x243.png"
                        width="257"
                        height="81"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={location.pathname} className="me-auto">
                        <Nav.Link href="/about" className="nav-link--ds mx-4">
                            {t('navbar.about')}
                        </Nav.Link>
                        <NavDropdown
                            title={t('navbar.ourCourses')}
                            id="basic-nav-dropdown"
                            className="mx-4"
                        >
                            <NavDropdown.Item href="/vykorystannya-fpv-v-boyovykh-umovakh">
                                {t('navbar.courseFpv')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="/vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh">
                                {t('navbar.courseMavic')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="/fpv-inzhenernyy-kurs">
                                {t('navbar.courseEngineer')}
                            </NavDropdown.Item>
                            {/*<NavDropdown.Divider/>*/}
                            {/*<NavDropdown.Item href="/nichnyy-bomber-shulika">*/}
                            {/*    {t('navbar.courseShulika')}*/}
                            {/*</NavDropdown.Item>*/}
                        </NavDropdown>
                        <Nav.Link href="/contact" className="nav-link--ds mx-4">
                            {t('navbar.contact')}
                        </Nav.Link>
                    </Nav>
                    <div className="navbar--ds-lang">
                        <svg
                            version="1.1"
                            viewBox="0 0 45.4 45.4">
<path d="M22.8,0C10.4,0,0.3,10.2,0.3,22.7s10.1,22.7,22.5,22.7s22.5-10.2,22.5-22.7S35.2,0,22.8,0z M41,29.2
	c-1.8,0.6-3.7,1.2-5.6,1.6c0.5-2.2,0.8-4.5,0.9-7.1h5.8C42,25.7,41.6,27.5,41,29.2z M3.4,23.8h5.8c0.1,2.4,0.4,4.8,0.9,7
	c-1.9-0.4-3.7-1-5.6-1.6C4,27.4,3.6,25.7,3.4,23.8z M4.6,15.5c1.8-0.6,3.7-1.2,5.6-1.6c-0.5,2.2-0.8,4.5-0.9,7H3.5
	C3.6,18.9,4,17.2,4.6,15.5z M24.6,12.8c2.5,0.1,4.9,0.3,7.4,0.8c0.7,2.2,1,4.7,1.1,7.2h-8.6L24.6,12.8L24.6,12.8z M24.5,9.7V3.3
	c2.6,0.7,5,3.2,6.6,7C28.9,10,26.7,9.8,24.5,9.7z M21.5,3.3v6.3c-2.2,0.1-4.4,0.2-6.6,0.5C16.5,6.6,18.9,4,21.5,3.3z M21.5,12.3v8.5
	h-9.1c0.1-2.7,0.6-5.3,1.2-7.7C16.3,12.6,18.9,12.4,21.5,12.3z M12.4,23.8h9.1v8.5c-2.6-0.1-5.2-0.3-7.8-0.8
	C13,29.1,12.6,26.5,12.4,23.8z M21.5,35.7V42c-2.6-0.7-5-3.2-6.6-7C17.1,35.4,19.3,35.6,21.5,35.7z M24.5,42v-6.3
	c2.2-0.1,4.4-0.2,6.6-0.5C29.5,38.8,27.1,41.3,24.5,42z M24.5,32.3v-8.5h9.1c-0.1,2.7-0.6,5.3-1.2,7.7C29.7,32,27.1,32.2,24.5,32.3z
	 M36.4,20.8c-0.1-2.5-0.4-4.8-0.9-7c1.9,0.4,3.7,1,5.6,1.6c0.6,1.7,1,3.5,1.2,5.3H36.4z M39.1,12c-1.5-0.5-3.1-0.8-4.7-1.2
	c-0.8-2.2-1.8-4.1-3-5.7C34.6,6.8,37.2,9.1,39.1,12z M14.1,5.1c-1.2,1.6-2.2,3.5-3,5.7c-1.6,0.3-3.1,0.7-4.7,1.2
	C8.4,9.1,11,6.8,14.1,5.1z M6.5,33.4c1.5,0.4,3.1,0.8,4.7,1.2c0.8,2.2,1.8,4.1,3,5.7C11,38.6,8.4,36.3,6.5,33.4z M31.5,40.2
	c1.2-1.6,2.2-3.5,3-5.7c1.6-0.3,3.1-0.7,4.6-1.1C37.2,36.3,34.6,38.6,31.5,40.2z"/>
</svg>
                        <div className="navbar--ds-lang-btns">
                            <span
                                className={`btn-ds-lang ${i18n.language === LANGUAGES.UA ? 'active' : ''}`}
                                onClick={handleLangBntClick}
                                lang={LANGUAGES.UA}
                            >
                                UA
                            </span>
                                <span
                                    className={`btn-ds-lang ${i18n.language === LANGUAGES.EN ? 'active' : ''}`}
                                    onClick={handleLangBntClick}
                                    lang={LANGUAGES.EN}
                                >
                                EN
                            </span>
                        </div>
                        <Button
                            href="/donation"
                            variant="outline-primary"
                            className="text-white text-uppercase my-3 my-lg-0"
                        >
                            {t('btn.donate')}
                        </Button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNav;
