import '../App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet';
import CoursesBlock from "../Components/CoursesBlock";
import { config } from "../constants.ts";

const Home = () => {
	const { t } = useTranslation();
	const { civil:civilFpv, military:militaryFpv } = config.price.courseFpv;
	const { civil:civilMavic, military:militaryMavic } = config.price.courseMavic;
	const { civil:civilEngineer, military:militaryEngineer } = config.price.courseEngineer;
	const { days:daysFpv, hrs:hrsFpv } = config.duration.courseFpv;
	const { days:daysMavic, hrs:hrsMavic } = config.duration.courseMavic;
	const { days:daysEngineer, hrs:hrsEngineer } = config.duration.courseEngineer;
	return (
		<div>
			<div className="home-video-out">
				<div className="home-video-container">
					<video
						className="home-video-in"
						playsInline
						muted
						loop
						src="/assets/hero_video_ds4.mp4"
						poster=""
						autoPlay
					></video>
				</div>
				<Container>
					<div className="hero--ds">
						<h1 className="pt-5 pb-4">
							{t('home.title')}
							<br />
							<span className="hero-line">{t('home.titleHero')}</span>
						</h1>
						<Button
							variant="primary"
							href="#our-services"
							className="text-white btn-xl text-uppercase"
						>
							{t('btn.homeHeroBtn')}
						</Button>
					</div>
					<div className="hero--ds-info">
						<Row className="justify-content-md-center">
							<Col lg={4}>
								{t('home.descriptionHero')}
							</Col>
							<Col lg={4} className="pt-5 pt-md-0">
								<Image src="/assets/ds-home-icon-4.png" width={110} />
								<Image src="/assets/ds-home-icon-1.png" width={110} />
								<Image src="/assets/ds-home-icon-5.png" width={110} />
								<Image src="/assets/ds-home-icon-2.png" width={110} />
								<Image src="/assets/ds-home-icon-3.png" width={110} />
								<Image src="/assets/ds-home-icon-6.png" width={110} />
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<div className="hero-sep--ds" />
			<div className="home-screen-2">
				<Container>
					<Row>
						<Col lg={6}></Col>
						<Col lg={6}>
							<h2 className="home-screen-2-h2">{t('home.titleScreen2')}</h2>
							<p className="home-screen-2-p pe-lg-5">
								{t('home.descriptionScreen2')}
							</p>
						</Col>
					</Row>
					<div className="home-screen-2-embed">
						<iframe
							width="700"
							height="394"
							src="https://www.youtube.com/embed/ZyaTEUhNZ6E?si=WFGuDKC0qUwW8pCI"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
						></iframe>
					</div>
				</Container>
			</div>
			<div className="home-screen-count-line">
				<Container>
					<Row>
						<Col lg={4} className="mb-5 mb-lg-0">
							<h3>350+</h3>
							<p>{t('home.greenLineCol1')}</p>
						</Col>
						<Col lg={4} className="mb-5 mb-lg-0">
							<h3>1000+</h3>
							<p>{t('home.greenLineCol2')}</p>
						</Col>
						<Col lg={4} className="mb-5 mb-lg-0">
							<h3>6</h3>
							<p>{t('home.greenLineCol3')}</p>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="home-block">
				<Container>
					<CoursesBlock/>
				</Container>
			</div>
			<div className="hero-sep--ds hero-sep--ds-gr" />
			<div className="home-block home-brand home-cta">
				<Container>
					<Row>
						<Col lg={7}>
							<h2 className="home-cta-title">
								<strong>🫡</strong> {t('home.ctaDonateTitle')}
							</h2>
						</Col>
						<Col lg={5}>
							<div className="home-cta-btn">
								<Button
									href="/donation"
									variant="primary"
									className="text-white btn-xl text-uppercase"
								>
									{t('btn.donate')}
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="home-block home-faq">
				<Container>
					<h2 className="home-screen-2-h2">{t('home.faqTitle')}</h2>
					<Accordion defaultActiveKey="-1">
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<h5>{t('home.faqQ1')}</h5>
							</Accordion.Header>
							<Accordion.Body>
								<p className="lead">
									{t('home.faqA1')}
								</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>
								<h5>{t('home.faqQ2')}</h5>
							</Accordion.Header>
							<Accordion.Body>
								<p className="lead">
									{t('home.faqA2',
										{
											militaryFpv,
											civilFpv,
											militaryMavic,
											civilMavic,
											militaryEngineer,
											civilEngineer
										}
										)}
								</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>
								<h5>{t('home.faqQ3')}</h5>
							</Accordion.Header>
							<Accordion.Body>
								<p className="lead">
									{t('home.faqA3',
										{
											daysFpv,
											hrsFpv,
											daysMavic,
											hrsMavic,
											daysEngineer,
											hrsEngineer
										}
									)}</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>
								<h5>{t('home.faqQ4')}</h5>
							</Accordion.Header>
							<Accordion.Body>
								<p className="lead">
									{t('home.faqA4')}
								</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header>
								<h5>{t('home.faqQ5')}</h5>
							</Accordion.Header>
							<Accordion.Body>
								<p className="lead">
									{t('home.faqA5')}
								</p>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Container>
			</div>
		</div>
	);
};

export default Home;
