import { useTranslation } from 'react-i18next';

function CoursePageFeatures() {
	const { t } = useTranslation();
	return (
		<div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">
					{t('coursePageFeatures.titleInstructors')}
				</h2>
				<p>{t('coursePageFeatures.bodyInstructors')}</p>
			</div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">
					{t('coursePageFeatures.titleLocation')}
				</h2>
				<p>{t('coursePageFeatures.bodyLocation')}</p>
			</div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">
					{t('coursePageFeatures.titleResidence')}
				</h2>
				<p>{t('coursePageFeatures.bodyResidence')}</p>
			</div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">{t('coursePageFeatures.titleFood')}</h2>
				<p>{t('coursePageFeatures.bodyFood')}</p>
			</div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">
					{t('coursePageFeatures.titleConditions')}
				</h2>
				<p>{t('coursePageFeatures.bodyConditions')}</p>
			</div>
			<div className="container-inner-info">
				<h2 className="container-inner-info-title">
					{t('coursePageFeatures.titleCoordination')}
				</h2>
				<p>{t('coursePageFeatures.bodyCoordination')}</p>
			</div>
		</div>
	);
}

export default CoursePageFeatures;
