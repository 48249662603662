import '../App.scss';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function Donation() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('donationPage.headTitle')} ■ {t('global.siteDescription')}</title>
				<meta name="description" content={t('donationPage.headDesc')} />
			</Helmet>
			<div className="donation-hero">
				<Container>
					<h1>{t('donationPage.title')}</h1>
				</Container>
			</div>
			<div>
				<Container className="donation-content">
					<Row>
						<Col md={6}>
							<p className="lead">
								{t('donationPage.description')}
							</p>
						</Col>
						<Col md={6}>
							<h3>Monobank</h3>
							<Image src="/assets/mono.png" width={300} />
							<h3>Privatbank</h3>
							<Image src="/assets/privat.png" width={280} />
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Donation;
