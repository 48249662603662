import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './languages/en.json';
import ukrainian from './languages/ua.json';
import { LANGUAGES } from './constants.ts';

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: LANGUAGES.UA,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		lng: LANGUAGES.UA,
		resources: {
			en: {
				translation: english,
			},
			ua: {
				translation: ukrainian,
			},
		},
	});

export const changeLanguage = code => {
	i18n.changeLanguage(code);
};

export default i18n;
