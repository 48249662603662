import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import i18n from "../i18n";
import {LANGUAGES} from "../constants.ts";

function InstructorsBlock(props) {
	const { instructors } = props;
	const { t } = useTranslation();
	return (
		<div className="instructors-block">
			<h2>{t('global.titleOurInstructors')}</h2>
			<div className="d-flex flex-wrap">
				{instructors.instructors.map(d => {
					return (
						<div className="p-2">
							<Card style={{ width: '18rem' }} key={d.name}>
								<Card.Img variant="top" src={d.img} />
								<Card.Body>
									<Card.Title>{i18n.language === LANGUAGES.EN ? d.nameEN : d.name}</Card.Title>
									<Card.Text>{i18n.language === LANGUAGES.EN ? d.descriptionEN : d.description}</Card.Text>
								</Card.Body>
							</Card>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default InstructorsBlock;
