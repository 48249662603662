import '../App.scss';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import CoursePageFeatures from '../Components/CoursePageFeatures';
import InstructorsHero from '../Components/InstructorsHero';
import instructors from '../Instructors/instructors.json';
import { Helmet } from 'react-helmet';
import CourseSummary from "../Components/CourseSummary";
import { config } from "../constants.ts";

function CourseFpv() {
	const { days:daysFpv, hrs:hrsFpv } = config.duration.courseFpv;
	const { t } = useTranslation();
	const paragraphs = t('courseFpv.bodyDescription', { returnObjects: true });
	const steps = t('courseFpv.steps', { returnObjects: true });
	const displayInstructors = instructors.instructors.filter(instructor =>
		instructor.tags.includes('fpv')
	);
	return (
		<div>
			<Helmet>
				<title>{t('courseFpv.headTitle')} ■ {t('global.siteDescription')}</title>
				<meta name="description" content={t('courseFpv.headDesc')} />
			</Helmet>
			<div className="container-hero">
				<Container>
					<div className="container-hero-holder d-sm-flex">
						<Image
							rounded
							className="me-5"
							src="/assets/ds-serv-icon-1.png"
							width={180}
							height={180}
							alt={t('courseFpv.title')}
						/>
						<div>
							<h1>{t('courseFpv.title')}</h1>
							<p className="container-hero-holder-line">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									viewBox="-5.0 -10.0 110.0 135.0"
								>
									<g>
										<path d="m38 59c0 3.3125-2.6875 6-6 6s-6-2.6875-6-6 2.6875-6 6-6 6 2.6875 6 6" />
										<path d="m77 14h-9v-3c0-1.6562-1.3438-3-3-3s-3 1.3438-3 3v3h-24v-3c0-1.6562-1.3438-3-3-3s-3 1.3438-3 3v3h-9c-4.9648 0-9 4.0352-9 9v54c0 4.9648 4.0352 9 9 9h54c4.9648 0 9-4.0352 9-9v-54c0-4.9648-4.0352-9-9-9zm-54 6h9v3c0 1.6562 1.3438 3 3 3s3-1.3438 3-3v-3h24v3c0 1.6562 1.3438 3 3 3s3-1.3438 3-3v-3h9c1.6523 0 3 1.3477 3 3v9h-60v-9c0-1.6523 1.3477-3 3-3zm54 60h-54c-1.6523 0-3-1.3477-3-3v-39h60v39c0 1.6523-1.3477 3-3 3z" />
										<path d="m71 56h-24c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h24c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z" />
									</g>
								</svg>
								{daysFpv} <small>{t('global.days')}</small>&nbsp;&nbsp;&nbsp;
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									viewBox="-5.0 -10.0 110.0 135.0"
								>
									<g>
										<path d="m50 86.641c9.7188 0 19.035-3.8594 25.91-10.73 6.8711-6.875 10.73-16.191 10.73-25.91s-3.8594-19.035-10.73-25.91c-6.875-6.8711-16.191-10.73-25.91-10.73s-19.035 3.8594-25.91 10.73c-6.8711 6.875-10.73 16.191-10.73 25.91 0.011719 9.7148 3.875 19.027 10.742 25.898 6.8711 6.8672 16.184 10.73 25.898 10.742zm0-68.281c8.3906 0 16.438 3.3359 22.371 9.2695s9.2695 13.98 9.2695 22.371-3.3359 16.438-9.2695 22.371-13.98 9.2695-22.371 9.2695-16.438-3.3359-22.371-9.2695-9.2695-13.98-9.2695-22.371c0.007813-8.3906 3.3438-16.434 9.2773-22.363 5.9297-5.9336 13.973-9.2695 22.363-9.2773z" />
										<path d="m48.719 52.148 12.43 7.4219c1.1797 0.64063 2.6523 0.23438 3.3398-0.91797 0.6875-1.1484 0.34375-2.6367-0.77734-3.3711l-11.211-6.7031v-23c0-1.3789-1.1211-2.5-2.5-2.5s-2.5 1.1211-2.5 2.5v24.422c0 0.88281 0.46484 1.6953 1.2188 2.1484z" />
									</g>
								</svg>
								{hrsFpv} <small>{t('global.hrs')}</small>
							</p>
						</div>
					</div>
					<InstructorsHero instructors={displayInstructors} />
				</Container>
			</div>
			<div className="container-inner">
				<Container>
					<Row>
						<Col md={8}>
							<div className="container-inner-info">
								<h2 className="container-inner-info-title">
									{t('global.titleCourseDescription')}
								</h2>
								{paragraphs.map(paragraph => (
									<p key={paragraph}>{paragraph}</p>
								))}
							</div>
							<div className="container-inner-info">
								<h2 className="container-inner-info-title">
									{t('global.titleProgramDetails')}
								</h2>
								<div className="steps">
									{steps.map((step, index) => (
										<div key={step} className="step">
											<div className="step-number">
												<div className="step-number-inner">{index + 1}</div>
											</div>
											<div className="step-body">
												<h3>
													{t('global.titleProgramDetailsDay')} {index + 1}
													.
												</h3>
												<p>{step}</p>
											</div>
										</div>
									))}
								</div>
								<CoursePageFeatures />
							</div>
						</Col>
						<Col md={4}>
							<CourseSummary course="courseFpv"/>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default CourseFpv;
