import {useTranslation} from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from 'react-bootstrap/Image';
import i18n from "../i18n";
import {LANGUAGES} from "../constants.ts";

const IstructorCard = ({name, img}) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="button-tooltip-inst1">
                    <h3>{name}</h3>
                </Tooltip>
            }
        >
            <Image roundedCircle src={img} width={90} height={90}/>
        </OverlayTrigger>
    );
};

function InstructorsHero({instructors}) {
    const {t} = useTranslation();
    return (
        <div className="container-hero-instr-holder">
            <div className="container-hero-instr">
                <h3>{t('global.titleHeroInstructors')}:</h3>
                {instructors.map(({nameEN, name, img}) => (
                    <IstructorCard
                        key={nameEN}
                        img={img}
                        name={i18n.language === LANGUAGES.EN ? nameEN : name}
                    />
                ))}
            </div>
        </div>
    );
}

export default InstructorsHero;
